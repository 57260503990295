<template>
<div id="home">
    <div class="sections">
        <div class="fade-out">
            <div class="content">
                <div class="d-block d-lg-none bg-lines">
                    <video playsinline autoplay muted loop id="sequence_bg">
                        <source :src='require("@/assets/videos/bg-lines.mp4")' type="video/mp4">
                    </video>
                </div>
                <div class="container" >
                    <div class="row section-header-height text-left" >
                        <div class="col-lg-12 header-flexing-auto align-self-center" data-aos="fade-in">
                            <!--<span>W{{ dWindow.innerWidth }} - H{{ dWindow.innerHeight }}</span>-->
                            <div class="m-auto">
                                <div class="text-header">
                                    {{ $t('header1') }}
                                    <span class="font-weight-bold">
                                        {{ $t('header2') }}
                                    </span>
                                    {{ $t('header3') }}
                                </div>
                                <div class="pt-1" >
                                    <img id="act" alt="ACTO logo" :src="require('@/assets/images/acto-logo.svg')">
                                </div>
                            </div>
                            <div class="btn-header-wrap">
                                <button 
                                    type="button" 
                                    class="btn btn-header" 
                                    @click="openFile()" >
                                    {{ $t('btn.header') }}
                                </button>
                            </div>
                            <div class="d-block d-lg-none">
                                <div class="chevron-footer header" @click="scrollToBottom('section_motivation')" >
                                    <img alt="chevron header" :src="require('@/assets/images/iconos/icon-chevron-down.svg')">
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="row section-full-height d-block d-lg-none" ref="section_motivation">
                        <div class="col-lg-12 align-self-center" data-aos="fade-in">
                            <div class="d-flex row justify-content-center" >
                                <div class="text-30" >{{ $t('home.ourBrands') }}</div>
                                <div class="text-18 pt-2" style="width: 300px;" >{{ $t('home.motivation') }}</div>
                            </div>
                            <div class="d-flex justify-content-center mt-4" >
                                <div class="col-sm-6">
                                    <a target="_blank" :href="'http://openinsurtech.com/'" rel="noreferrer">
                                        <div class="wrap-image">
                                            <div class="wrap-icon m-auto">
                                                <img alt="logo-openinsurtech" :src="require('@/assets/images/logos/logo-openinsurtech.png')" style="width: 139px"/>
                                            </div>
                                        </div>
                                    </a>
                                    <a target="_blank" :href="'https://acto.io/'" rel="noreferrer">
                                        <div class="d-flex mx-auto flex-column align-items-center justify-content-center wrap-image">
                                            <div class="wrap-icon">
                                                <img alt="logo-acto-membresias" :src="require('@/assets/images/logos/logo-acto-membresias.png')" style="width: 82px" />
                                            </div>
                                        </div>
                                    </a>
                                    <a target="_blank" :href="'https://miahub.org/'" rel="noreferrer">
                                        <div class="d-flex mx-auto flex-column align-items-center justify-content-center wrap-image">
                                            <div class="wrap-icon">
                                                <img alt="logo-mia-hub" :src="require('@/assets/images/logos/logo-miami-hub.png')" style="width: 116px" />
                                            </div>
                                        </div>
                                    </a>
                                </div>
                                <div class="col-sm-6">
                                    <a target="_blank" :href="'https://www.segupay.io/'" rel="noreferrer">
                                        <div class="d-flex mx-auto flex-column align-items-center justify-content-center wrap-image" style="margin-top: 7rem;">
                                            <div class="wrap-icon">
                                                <img alt="logo-segupay" :src="require('@/assets/images/logos/logo-segupay.png')" style="width: 132px" />
                                            </div>
                                        </div>
                                    </a>
                                    <a target="_blank" :href="'https://actoseguros.com'" rel="noreferrer">
                                        <div class="d-flex mx-auto flex-column align-items-center justify-content-center wrap-image">
                                            <div class="wrap-icon">
                                                <img alt="logo-acto-seguros" :src="require('@/assets/images/logos/logo-acto-seguros.png')" style="width: 90px" />
                                            </div>
                                        </div>
                                    </a>
                                </div>
                            </div>
                            <div class="chevron-footer brands" @click="scrollToBottom('section_purpose')" >
                                <img alt="chevron header" :src="require('@/assets/images/iconos/icon-chevron-down.svg')">
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col-12 d-none d-lg-block"  style="padding: 0;">
                    <vue-marquee-slider
                        id="marquee-slider"
                        :speed="30000"
                        :width="defaultWidth"
                    >
                        <div class="wrap-icon">
                            <div>
                                <a target="_blank" :href="'https://www.segupay.io/'" rel="noreferrer">
                                    <div class="border-icon" >
                                        <img alt="logo-segupay" :src="require('@/assets/images/iconos/icon-segupay.png')" />
                                    </div>
                                </a>
                            </div>
                            <div class="text">
                                Segupay
                            </div>
                            <div class="btn">
                                <a target="_blank" :href="'https://www.segupay.io/'" rel="noreferrer">
                                    {{ $t('text.link.web') }}
                                </a>
                            </div>
                        </div>
                        <div class="wrap-icon">
                            <div>
                                <a target="_blank" :href="'https://actoseguros.com'" rel="noreferrer">
                                    <div class="border-icon" >
                                        <img alt="logo-acto-seguros" :src="require('@/assets/images/iconos/icon-acto-seguros.png')" />
                                    </div>
                                </a>
                            </div>
                            <div class="text">
                                Acto Seguros
                            </div>
                            <div class="btn">
                                <a target="_blank" :href="'https://actoseguros.com'" rel="noreferrer">
                                    {{ $t('text.link.web') }}
                                </a>
                            </div>
                        </div>
                        <div class="wrap-icon">
                            <div>
                                <a target="_blank" :href="'https://acto.io/'" rel="noreferrer">
                                    <div class="border-icon" >
                                        <img alt="logo-acto-membresias" :src="require('@/assets/images/iconos/icon-acto-membresias.png')" />
                                    </div>
                                </a>
                            </div>
                            <div class="text">
                                Acto Membresías
                            </div>
                            <div class="btn">
                                <a target="_blank" :href="'https://acto.io/'" rel="noreferrer">
                                    {{ $t('text.link.web') }}
                                </a>
                            </div>
                        </div>
                        <div class="wrap-icon">
                            <div>
                                <a target="_blank" :href="'http://openinsurtech.com/'" rel="noreferrer">
                                    <div class="border-icon" >
                                        <img alt="logo-openinsurtech" :src="require('@/assets/images/iconos/icon-openinsurtech.png')" />
                                    </div>
                                </a>
                            </div>
                            <div class="text">
                                Open Insurtech
                            </div>
                            <div class="btn">
                                <a target="_blank" :href="'http://openinsurtech.com/'" rel="noreferrer">
                                    {{ $t('text.link.web') }}
                                </a>
                            </div>
                        </div>
                        <div class="wrap-icon">
                            <div>
                                <div class="border-icon" >
                                    <a target="_blank" :href="'https://miahub.org'" rel="noreferrer">
                                        <img alt="logo-mia-hub" :src="require('@/assets/images/iconos/icon-mia-hub.png')" />
                                    </a>
                                </div>
                            </div>
                            <div class="text">
                                Miami Insurtech Advocates Hub
                            </div>
                            <div class="btn">
                                <a target="_blank" :href="'https://miahub.org'" rel="noreferrer">
                                    {{ $t('text.link.web') }}
                                </a>
                            </div>
                        </div>
                    </vue-marquee-slider>
                </div>
            </div>
        </div>
        <div class="fade-out bg-blue second-div" ref="section_purpose">
            <div class="content">
                <div class="container">
                    <div class="row section-full-height justify-content-center">
                        <div class="col-lg-12 align-self-center d-block d-lg-none" data-aos="fade-in">
                            <div class="video-purpose">
                                <video playsinline autoplay muted loop id="purpose">
                                    <source :src='require("@/assets/videos/purpose.mp4")' type="video/mp4">
                                </video>
                            </div>
                            <div class="pt-5" >
                                <h3>
                                    {{ $t('texto2') }}
                                </h3>
                            </div>
                            <div class="chevron-footer purpose" @click="scrollToBottom('section_news')" >
                                <img alt="chevron header" :src="require('@/assets/images/iconos/icon-chevron-down-white.svg')">
                            </div>
                        </div>
                        <div class="col-lg-12 align-self-center d-none d-lg-block" data-aos="fade-in">
                            <div class="pt-5" >
                                <h3>
                                    {{ $t('texto2') }}
                                </h3>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col-12 d-none d-lg-block"  style="padding: 0px;">
                    <div class="wrap-news"></div>
                    <vue-marquee-slider
                        id="marquee-slider-news"
                        :speed="30000"
                        :reverse="true"
                        :width="defaultWidthNews"
                    >
                        <div class="wrap-icon-news">
                            <a target="_blank" :href="'https://app.dealroom.co/lists/33620?fbclid=PAAaZ6CkQDamwxz1ynCoJqo9eW5l287sje55JRpqdO9lOjBJOyi3l6AzVKNoE'" rel="noreferrer">
                                <img alt="logo-dealroom" style="width: 145px" :src="require('@/assets/images/iconos/logo-deal-room.png')" />
                            </a>
                        </div>
                        <div class="wrap-icon-news">
                            <a target="_blank" :href="'https://www.elfinanciero.com.mx/mundo-empresa/2023/01/16/celebran-alianza-mapfre-y-grupo-acto-en-beneficio-de-las-familias-mexicanas/?outputType=amp&fbclid=PAAaYJUg-RlcIPZWjcN4WtI95rCr8AlbVx9698XIgygffFDi0MRjw2nlME47o'" rel="noreferrer">
                                <img alt="logo-el-financiero" style="width: 145px" :src="require('@/assets/images/iconos/logo-elfinanciero-white.png')" />
                            </a>
                        </div>
                        <div class="wrap-icon-news">
                            <a target="_blank" :href="'https://www.youtube.com/watch?app=desktop&v=lHLAhI3du_0&fbclid=PAAaYFjxMrgLVZgc6fHkBOxhE-GrCgR-yJ1tRYgxzepX90HMJsgAHDew3qCzg'" rel="noreferrer">
                                <img alt="logo-camara-comercio" style="width: 145px" :src="require('@/assets/images/iconos/logo-camara-comercio.png')" />
                            </a>
                        </div>
                        <div class="wrap-icon-news">
                            <a target="_blank" :href="'https://jf.media/12-most-promising-insurtech-startups-with-seed-funding-mexico/'" rel="noreferrer">
                                <img alt="logo-plataforma" style="width: 145px" :src="require('@/assets/images/iconos/logo-plataforma.png')" />
                            </a>
                        </div>
                    </vue-marquee-slider>
                </div>
            </div>
        </div>
        <div class="fade-out d-block d-lg-none" ref="section_news" >
            <div class="content">
                <div class="container">
                    <div class="row section-middle-height">
                        <div class="col-lg-12 align-self-center" data-aos="fade-in" style="padding: 0;">
                            <div class="wrap-icon-master">
                                <div class="wrap-icon-news">
                                    <a target="_blank" :href="'https://app.dealroom.co/lists/33620?fbclid=PAAaZ6CkQDamwxz1ynCoJqo9eW5l287sje55JRpqdO9lOjBJOyi3l6AzVKNoE'" rel="noreferrer">
                                        <img alt="logo-dealroom" :src="require('@/assets/images/iconos/logo-deal-room.png')" />
                                    </a>
                                </div>
                                <div class="wrap-icon-news">
                                    <a target="_blank" :href="'https://www.elfinanciero.com.mx/mundo-empresa/2023/01/16/celebran-alianza-mapfre-y-grupo-acto-en-beneficio-de-las-familias-mexicanas/?outputType=amp&fbclid=PAAaYJUg-RlcIPZWjcN4WtI95rCr8AlbVx9698XIgygffFDi0MRjw2nlME47o'" rel="noreferrer">
                                        <img alt="logo-el-financiero" :src="require('@/assets/images/iconos/logo-elfinanciero-white.png')" />
                                    </a>
                                </div>
                                <div class="wrap-icon-news">
                                    <a target="_blank" :href="'https://www.youtube.com/watch?app=desktop&v=lHLAhI3du_0&fbclid=PAAaYFjxMrgLVZgc6fHkBOxhE-GrCgR-yJ1tRYgxzepX90HMJsgAHDew3qCzg'" rel="noreferrer">
                                        <img alt="logo-camara-comercio" :src="require('@/assets/images/iconos/logo-camara-comercio.png')" />
                                    </a>
                                </div>
                                <div class="wrap-icon-news">
                                    <a target="_blank" :href="'https://jf.media/12-most-promising-insurtech-startups-with-seed-funding-mexico/'" rel="noreferrer">
                                        <img alt="logo-plataforma" :src="require('@/assets/images/iconos/logo-plataforma.png')" />
                                    </a>
                                </div>
                                <div class="wrap-icon-news">
                                    <a target="_blank" :href="'https://www.theunconferences.com/fintech-unconference-mexico-2022?fbclid=PAAaYhzw8pi-p9P1oOiHtfDb8kx7GoMyNwgJ8jlsQkW5oNG3QP2W_1yGBV79o'" rel="noreferrer">
                                        <img style="width: 225px;" alt="logo-finnovating" :src="require('@/assets/images/iconos/logo-finnovating.png')" />
                                    </a>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="fade-out bg-blue div-connect" >
            <div class="content">
                <div class="container">
                    <div class="row section-full-height justify-content-center">
                        <div class="col-lg-12 align-self-center order-0 order-lg-1" data-aos="fade-in">
                            <div class="video-connect mx-auto mb-5 d-block d-lg-none">
                                <video playsinline autoplay muted loop id="connect">
                                    <source :src='require("@/assets/videos/connect.mp4")' type="video/mp4">
                                </video>
                            </div>
                            <h3 class="mx-auto mt-2" >
                                {{ $t('texto3') }}
                            </h3>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
</template>

<script>
//import i18n from '@/plugins/i18n';
const $ = require('jquery')
window.$ = $

export default {
    name: "Home",
    components: {
        //LottieAnimation,
    },
    data() {
        return {
            defaultWidth: 200,
            defaultWidthNews: 200,
            dWindow: window
        }
    },
    created() {
        if(window.innerWidth <= 432) {
            this.defaultWidth = 20
            this.defaultWidthNews = 20
        }
    },
    mounted() {
    },
    methods: {
        openFile() {
            window.open("./documents/bronchure.pdf", '_blank')
        },
        scrollToBottom(section) {
            this.$refs[section].scrollIntoView({ behavior: "smooth" })
        }
         //changeLocale(locale) {
       
        //localStorage['locale'] = locale; // only strings
        //i18n.locale = locale;
    }
    }




</script>

<style>
#act{
    width: 40%;
    margin-right: -20px;
}
@media (max-width:999px){ 
    #act{
        width: 70%;
    }
}




#in{
    color: #5ABA56;
}
.fade-out{-webkit-animation:fade-out-bck .7s cubic-bezier(.25,.46,.45,.94) both;animation:fade-out-bck .7s cubic-bezier(.25,.46,.45,.94) both}
</style>
