<template>
<div id="nav">
    <b-navbar toggleable="lg" type="dark" class="navbar navbar-expand-lg active @fixedNarvar" id="main-nav">
        <b-navbar-brand>
            <router-link id="router-link" to="/">
                <img :src="require('@/assets/images/acto-logo.svg')" alt="Acto" loading="lazy" />
            </router-link>
        </b-navbar-brand>

        <b-navbar-toggle target="nav-collapse"></b-navbar-toggle>

        <b-collapse id="nav-collapse" is-nav>
            <ul class="navbar-nav mx-auto mt-2 mt-lg-0">
                <b-nav-item-dropdown :text="$t('brands')" right>
                    <b-dropdown-item target="_blank" href="https://actoseguros.com">
                        <img alt="logo-acto-seguros-menu" style="width:40px; border: 1px solid black; border-radius: 50%; margin-right: 10px;" :src="require('@/assets/images/iconos/icon-acto-seguros.png')" />
                        Acto Seguros
                    </b-dropdown-item>
                    <b-dropdown-item target="_blank" href="https://acto.io">
                        <img alt="logo-acto-membresias" style="width:40px;margin-right: 10px;" :src="require('@/assets/images/iconos/icon-acto-membresias.png')" />
                        Acto Membresías
                    </b-dropdown-item>
                    <b-dropdown-item target="_blank" href="http://openinsurtech.com">
                        <img alt="logo-openinsurtech-menu" style="width:40px;margin-right: 10px;" :src="require('@/assets/images/iconos/icon-openinsurtech.png')" />
                        Open Insurtech
                    </b-dropdown-item>
                    <b-dropdown-item target="_blank" href="https://miahub.org">
                        <img alt="logo-mia-hub-membresias" style="width:40px; margin-right: 10px; border: 1px solid black; border-radius: 50%;" :src="require('@/assets/images/iconos/icon-mia-hub.png')" />
                        Miami Insurtech Advocates Hub
                    </b-dropdown-item>
                    <b-dropdown-item target="_blank" href="https://segupay.io">
                        <img alt="logo-segupay-menu" style="width:40px; margin-right: 10px;" :src="require('@/assets/images/iconos/icon-segupay.png')" />
                        Segupay
                    </b-dropdown-item>
                </b-nav-item-dropdown>
                <li class="nav-item">
                    <a class="nav-link js-scroll-trigger">
                        <router-link id="router-link" to="/quienes-somos">{{ $t('nosotros') }}</router-link>
                    </a>
                </li>
                <!--
                <li class="nav-item dropdown">
                    <a class="nav-link">
                        <router-link id="router-link" to="/membresias">{{ $t('membresias') }}</router-link>
                    </a>
                </li>
                <li class="nav-item dropdown">
                    <a class="nav-link dropdown-toggle d-block d-lg-none" style="color: white;" target="_blank" href="https://.actoseguros.mx" id="solutionsDropdown" role="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                        <router-link id="router-link" style="color: white;" to="https://actoseguros.mx" target="_blank">{{ $t('seguros') }}</router-link>
                    </a>
                    <a class="nav-link dropdown-toggle d-none d-lg-block" href="https://actoseguros.mx" target="_blank" id="solutionsDropdown" role="button">{{ $t('seguros') }}</a>
                    <div class="dropdown-menu" aria-labelledby="solutionsDropdown">
                        <a class="dropdown-item" href="https://actoseguros.mx/salud" target="_blank" style="color: #00264D;">{{ $t('salud') }}</a>
                        <a class="dropdown-item" href="https://actoseguros.mx/vida" target="_blank" style="color: #00264D;">{{ $t('vida') }}</a>
                        <a class="dropdown-item" href="https://actoseguros.mx/hogar" target="_blank" style="color: #00264D;">{{ $t('hogar') }}</a>
                        <a class="dropdown-item" href="https://actoseguros.mx/empresa" target="_blank" style="color: #00264D;">{{ $t('empresa') }}</a>
                        <a class="dropdown-item" href="https://actoseguros.mx/auto" target="_blank" style="color: #00264D;">{{ $t('auto') }}</a>
                        <a class="dropdown-item" href="https://actoseguros.mx/moto" target="_blank" style="color: #00264D;">{{ $t('motocicleta') }}</a>
                        <a class="dropdown-item" href="https://actoseguros.mx/viaje" target="_blank" style="color: #00264D;">{{ $t('viaje') }}</a>
                        <a class="dropdown-item" href="https://actoseguros.mx/mascota" target="_blank" style="color: #00264D;">{{ $t('mascota') }}</a>
                    </div>
                </li>
                <li class="nav-item">
                    <a class="nav-link">
                        <router-link to="/fianzas" style="color: gray;">{{ $t('fianzas') }}</router-link>
                    </a>
                </li>
                -->
                <li class="nav-item">
                    <a class="nav-link" target="_blank" href="https://blog.grupoacto.com/">
                        {{ $t('blog') }}
                    </a>
                </li>
            </ul>

            <ul class="navbar-nav d-flex">
                <!--
                <li class="nav-item">
                    <a class="nav-link">
                        <router-link id="router-link" to="/login">{{ $t('login') }}</router-link>
                    </a>
                </li>
                -->
                <li class="nav-item">
                    <a class="nav-link">
                        <router-link id="router-link" to="/contacto">{{ $t('contacto') }}</router-link>
                    </a>
                </li>
                <b-nav-item-dropdown :text="$t('idioma')" right>
                    <b-dropdown-item v-for="entry in languages" :key="entry.title" @click="changeLocale(entry.language)">
                        <flag :iso="entry.flag" v-bind:squared=false /> {{entry.title}}
                    </b-dropdown-item>
                </b-nav-item-dropdown>

                <!--<li class="nav-item dropdown">
                    <a class="nav-link dropdown-toggle d-block d-lg-none" id="languajeDropdown" role="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                        <router-link id="router-link" to="">{{ $t('idioma') }}s</router-link>
                    </a>
                    <a class="nav-link dropdown-toggle d-none d-lg-block" id="languajeDropdown" role="button">
                        <router-link id="router-link" to="">{{ $t('idioma') }}</router-link>
                    </a>
                    <div class="dropdown-menu" aria-labelledby="languajeDropdown">
                        <a class="dropdown-item" style="color: black;" v-for="entry in languages" :key="entry.title" @click="changeLocale(entry.language)">
                            <flag :iso="entry.flag" v-bind:squared=false /> {{entry.title}}
                            <router-link id="router-link" to="" class="dropdown-item">{{ $t('nav.esp') }}</router-link>
                        </a>
                        <a class="dropdown-item" >
                            <router-link id="router-link" to="" class="dropdown-item">{{ $t('nav.ing') }}</router-link>
                        </a>
                        <a class="dropdown-item">
                            <router-link id="router-link" to="" class="dropdown-item">{{ $t('nav.jpn') }}</router-link>
                        </a>
                    </div>
                </li>-->
            </ul>
        </b-collapse>
    </b-navbar>
</div>
</template>

<script>
import i18n from '@/plugins/i18n';

export default {
    data() {
        return {
            languages: [
                {
                    flag: 'mx',
                    language: 'es',
                    title: 'Español'
                },
                {
                    flag: 'us',
                    language: 'en',
                    title: 'English'
                }/* ,
                {
                    flag: 'de',
                    language: 'de',
                    title: 'Aleman'
                },
                {
                    flag: 'jp',
                    language: 'jp',
                    title: 'Japones'
                },
                {
                    flag: 'kr',
                    language: 'kr',
                    title: 'Coreano'
                } */
            ]
        }
    },
    methods: {
    changeLocale(locale) {
        
        //alert("cambio");
        //alert(locale);
        //console.log(i18n);
        //localStorage['locale'] = locale; // only strings
        
        i18n.locale = locale;
        //locale: window.navigator.language.split('-')[0];
    }
}

}
</script>

<style>

</style>
