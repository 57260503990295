<template>
<div id="footer">
    <footer class="footer-container text-left ffooter" >
        <div class="container">
            <div class="row d-none d-lg-block">
                <div class="col-md-5 footer-contact">
                    <img :src="require('@/assets/images/logo-azul.png')" alt="Acto" loading="lazy" class="img-fluid footer-logo mb-3">
                </div>
                <div class="col-md-7 mt-3 mt-lg-0">
                </div>
                <div class="col-md-12">
                    <div class="row">
                        <div class="col-7 col-md-5 footer-contact">
                            <p class="copyright">
                                Oficina Guadalajara
                            </p>
                            <p class="mb-1 directions">
                                Av. de las americas 1586<br/>
                                Lomas Providencia<br/>
                                Guadalajara, Jalisco, México  44610
                            </p>
                            <br>
                            <p class="copyright">
                                Oficina CDMX
                            </p>
                            <p class="mb-1 directions">
                                Chicontepec 57<br/>
                                Hipódromo Condesa<br/> 
                                Cuauhtémoc, CDMX, México  06170
                            </p>
                            <br>
                            <p class="copyright">
                                COPYRIGHT © {{ new Date().getFullYear() }}  <br />
                                TODOS LOS DERECHOS RESERVADOS <br />
                            </p>
                        </div>
                        <div class="col-md-7 row">
                            <div class="col-md-4 footer-links">
                                <p class="list-title">
                                    <a href="mailto:ventas@grupoacto.com" class="mail-link mb-1">{{ $t('correo') }}</a>
                                </p>
                                <p class="list-title">
                                    <a href="tel:+523312417161" class="mail-link mb-1">{{ $t('phone1') }}</a>
                                </p>
                                <p class="list-title">
                                    <a href="tel:+523314101269" class="mail-link mb-1">{{ $t('phone2') }}</a>
                                </p>
                                <ul class="navbar-nav flex-row social-list col-md-8 mt-3 mt-lg-4">
                                    <li class="nav-item col-md-4">
                                        <a class="nav-link" href="https://instagram.com/acto.holding?igshid=1i8joshb4vscf"><i class="fab fa-instagram"></i></a>
                                    </li>
                                    <li class="nav-item col-md-4">
                                        <a class="nav-link" href="https://facebook.com/graciasacto"><i class="fab fa-facebook-f"></i></a>
                                    </li>
                                    <li class="nav-item col-md-4">
                                        <a class="nav-link" href="https://www.linkedin.com/company/grupoacto"><i class="fab fa-linkedin"></i></a>
                                    </li>
                                </ul>
                            </div>
                            <div class="col-md-4 footer-links">
                                <p class="list-title">{{ $t('brands') }}</p>
                                <ul class="footer-list">
                                    <li>
                                        <a target="_blank" :href="'http://openinsurtech.com/'">Open Insurtech</a>
                                    </li>
                                    <li>
                                        <a target="_blank" :href="'http://acto.io/'">Acto Membresias</a>
                                    </li>
                                    <li>
                                        <a target="_blank" :href="'https://www.segupay.io/'">Segupay</a>
                                    </li>
                                    <li>
                                        <a target="_blank" :href="'https://actoseguros.com'">Acto Seguros</a>
                                    </li>
                                    <li>
                                        <a target="_blank" :href="'https://miahub.org/'">MIA Hub</a>
                                    </li>
                                </ul>
                            </div>
                            <div class="col-md-4 footer-links">
                                <p class="list-title">
                                    <a>
                                        <router-link to="/quienes-somos">{{ $t('nosotros') }}</router-link>
                                    </a>
                                </p>
                                <p class="list-title" >
                                    <a target="_blank" href="https://blog.grupoacto.com/">{{ $t('blog') }}</a>
                                </p>
                                <p class="list-title">
                                    <a>
                                        <router-link to="/contacto">{{ $t('contacto') }}</router-link>
                                    </a>
                                </p>
                                <p class="list-title">
                                    <a target="_blank" href="https://drive.google.com/file/d/1fS0KZtdlf4tN-MLQatiyf9-ZnT62r5WG/view?usp=sharing">
                                        Aviso de Privacidad
                                    </a>
                                </p>
                                <p class="list-title">
                                    <a>
                                        <router-link to="/faq">FAQ</router-link>
                                    </a>
                                </p>
                            </div>
                            <div class="col-md-12 row align-items-center">
                                <div class="col-md-4" >
                                    <img alt="logo-condusef" style="width: 100%;" :src="require('@/assets/images/footer/condusef.png')"/>
                                </div>
                                <div class="col-md-4" >
                                    <img alt="logo-cnsf" style="width: 100%;" :src="require('@/assets/images/footer/cnsf.png')"/>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="row d-block d-lg-none">
                <div class="col-12 footer-contact">
                    <img :src="require('@/assets/images/iconos/logo-mobile-footer-acto.svg')" alt="Acto" loading="lazy" class="img-fluid footer-logo mb-3">
                </div>
                <div class="col-12">
                    <div class="row text-left justify-content-center">
                        <div class="col-6 footer-links p-4">
                            <p class="list-title">{{ $t('brands') }}</p>
                            <ul class="footer-list">
                                <li>
                                    <a target="_blank" :href="'http://openinsurtech.com/'">Open Insurtech</a>
                                </li>
                                <li>
                                    <a target="_blank" :href="'http://acto.io/'">Acto Membresias</a>
                                </li>
                                <li>
                                    <a target="_blank" :href="'https://www.segupay.io/'">Segupay</a>
                                </li>
                                <li>
                                    <a target="_blank" :href="'https://actoseguros.com'">Acto Seguros</a>
                                </li>
                                <li>
                                    <a target="_blank" :href="'https://miahub.org'">MIA Hub</a>
                                </li>
                            </ul>
                        </div>
                        <div class="col-6 footer-links p-4">
                            <p class="list-title">
                                <a>
                                    <router-link to="/quienes-somos">{{ $t('nosotros') }}</router-link>
                                </a>
                            </p>
                            <p class="list-title" >
                                <a target="_blank" href="https://blog.grupoacto.com/">{{ $t('blog') }}</a>
                            </p>
                            <p class="list-title">
                                <a>
                                    <router-link to="/contacto">{{ $t('contacto') }}</router-link>
                                </a>
                            </p>
                            <p class="list-title">
                                <a target="_blank" href="https://drive.google.com/file/d/1fS0KZtdlf4tN-MLQatiyf9-ZnT62r5WG/view?usp=sharing">
                                    Aviso de Privacidad
                                </a>
                            </p>
                            <p class="list-title">
                                <a>
                                    <router-link to="/faq">FAQ</router-link>
                                </a>
                            </p>
                        </div>
                        <div class="col-6"></div>
                        <div class="col-6 px-4 footer-links">
                            <p class="list-title">
                                <a href="mailto:ventas@grupoacto.com" class="mail-link mb-1">{{ $t('correo') }}</a>
                            </p>
                            <p class="list-title">
                                <a href="tel:+523314101269" class="mail-link mb-1">{{ $t('phone1') }}</a>
                            </p>
                            <p class="list-title">
                                <a href="tel:+523314101269" class="mail-link mb-1">{{ $t('phone2') }}</a>
                            </p>
                        </div>
                        <div class="col-12 footer-links">
                            <ul class="navbar-nav flex-row social-list justify-content-between mt-3 px-2">
                                <li class="nav-item">
                                    <a class="nav-link" href="https://instagram.com/acto.holding?igshid=1i8joshb4vscf"><i class="fab fa-instagram"></i></a>
                                </li>
                                <li class="nav-item">
                                    <a class="nav-link" href="https://facebook.com/graciasacto"><i class="fab fa-facebook-f"></i></a>
                                </li>
                                <li class="nav-item">
                                    <a class="nav-link" href="https://www.linkedin.com/company/grupoacto"><i class="fab fa-linkedin"></i></a>
                                </li>
                            </ul>
                        </div>
                        <div class="col-6 p-4 footer-contact">
                            <p class="copyright">
                                Oficina Guadalajara
                            </p>
                            <p class="mb-1 directions">
                                Av. de las americas 1586<br />
                                Lomas Providencia <br />
                                Guadalajara, Jalisco, México  44610
                            </p>
                        </div>
                        <div class="col-6 p-4 footer-contact">
                            <p class="copyright">
                                Oficina CDMX
                            </p>
                            <p class="mb-1 directions">
                                Chicontepec 57<br/>
                                Hipódromo Condesa<br/> 
                                Cuauhtémoc, CDMX, México  06170
                            </p>
                        </div>
                        <div class="col-12 row align-items-center">
                            <div class="col-sm-3 col-6 text-center" >
                                <img alt="logo-condusef" style="width: 100%;" :src="require('@/assets/images/footer/condusef.png')"/>
                            </div>
                            <div class="col-sm-3 col-6" >
                                <img alt="logo-cnsf" style="width: 100%;" :src="require('@/assets/images/footer/cnsf.png')"/>
                            </div>
                        </div>
                        <div class="col-12 p-4 pt-4">
                            <p class="copyright text-center">
                                COPYRIGHT © {{ new Date().getFullYear() }}  <br />
                                TODOS LOS DERECHOS RESERVADOS <br />
                            </p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </footer>
</div>
</template>

<script>
export default {

}
</script>

<style>

.footer-zoho-image{
    padding: 20px;
    width: 100%;
    height: 100%;

}

.footer-social-overlap {
    position: relative;
    z-index: 2;
    background: transparent;
    padding: 0 20px
}

.footer-social-connect {
    display: flex;
    align-items: center;
    font: 3.5em 'Montserrat', sans-serif;
    color: #fff
}

.footer-social-small {
    font-size: 0.6em;
    padding: 0px 20px
}

.footer-social-overlap>a {
    font-size: 3em
}

.footer-social-overlap>a:not(:first-child) {
    margin-left: 0.38em
}


.footer-bottom-section>div:first-child {
    margin-right: auto
}

.footer-bottom-wrapper {
    font-size: 1.5em;
    color: #fff
}

.footer-address {
    display: inline;
    font-style: normal
}

@media only screen and (max-width: 768px) {
    .footer-list-header {
        font-size: 2em
    }

    .footer-list-anchor {
        font-size: 1.1em
    }

    .footer-social-connect {
        font-size: 2.5em
    }

    .footer-social-overlap>a {
        font-size: 2.24em
    }

    .footer-bottom-wrapper {
        font-size: 1.3em
    }
}

@media only screen and (max-width: 568px) {
    main {
        font-size: 5em
    }

    .footer-list-top {
        width: 100%
    }

    .footer-list-header {
        font-size: 3em;
    }

    .footer-list-anchor {
        font-size: 1.5em
    }

    .footer-social-section {
        justify-content: center
    }

    .footer-social-section::after {
        top: 25%
    }

    .footer-social-connect {
        margin-bottom: 10px;
        padding: 0 10px
    }

    .footer-social-overlap {
        display: flex;
        justify-content: center;
    }

    .footer-social-icons-wrapper {
        width: 100%;
        padding: 0
    }

    .footer-social-overlap>a:not(:first-child) {
        margin-left: 20px;
    }

    .footer-bottom-section {
        padding: 0 5px 10px 5px
    }

    .footer-bottom-wrapper {
        text-align: center;
        width: 100%;
        margin-top: 10px
    }
}

@media only screen and (max-width: 480px) {
    .footer-social-overlap>a {
        margin: auto
    }

    .footer-social-overlap>a:not(:first-child) {
        margin-left: 0;
    }

    .footer-bottom-rights {
        display: block
    }
}

@media only screen and (max-width: 320px) {
    .footer-list-header {
        font-size: 2.2em
    }

    .footer-list-anchor {
        font-size: 1.2em
    }

    .footer-social-connect {
        font-size: 2.4em
    }

    .footer-social-overlap>a {
        font-size: 2.24em
    }

    .footer-bottom-wrapper {
        font-size: 1.3em
    }
}
</style>
